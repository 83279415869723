/*  light theme */
:root{
    /* base color*/
    --0plus-base-color-white: #fff;
    --0plus-base-color-black: #000;
    /*text color*/
    color: #000;
    
    /* gray color */
    --0plus-gray-color-25: #FCFCFD;
    --0plus-gray-color-50: #F9FAFB;
    --0plus-gray-color-100: #F2F4F7;
    --0plus-gray-color-200: #EAECF0;
    --0plus-gray-color-300: #D0D5DD;
    --0plus-gray-color-400: #98A2B3;
    --0plus-gray-color-500: #667085;
    --0plus-gray-color-600: #475467;
    --0plus-gray-color-700: #344054;
    --0plus-gray-color-800: #1D2939;
    --0plus-gray-color-90: #101828;
    
    /* backgroung setting */
    --0plus-background: #fff;

    /* primary color setting*/
    --0plus-primary-color-25: #f3f3fc;
    --0plus-primary-color-50: #ebeafa;
    --0plus-primary-color-100: #d8d7f6;
    --0plus-primary-color-200: #bebdf0;
    --0plus-primary-color-300: #9e9ce8;
    --0plus-primary-color-400: #7e7be0;
    --0plus-primary-color-500: #3d39d1;
    --0plus-primary-color-600: #3330ae;
    --0plus-primary-color-700: #29268b;
    --0plus-primary-color-800: #1f1d69;
    --0plus-primary-color-900: #141346;

}
